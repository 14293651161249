import React from "react"
import { HCF, HUtils } from "@macashipo/mlib"
import { Helmet } from "react-helmet"
import { ConnectedProps, connect } from "react-redux"
const mapStateToProps = state => ({
  dataFirstSetting: state.app.dataFirstSetting,
})
const mapDispatchToProps = {}
const connector = connect(mapStateToProps, mapDispatchToProps)
interface Props extends ConnectedProps<typeof connector> {
  data
}

const MyUIHelmet = (props: Props) => {
  const { data, dataFirstSetting } = props
  const dfMetaConfig = HCF.getCF("dfMetaConfig")
  const metaTitle = data?.MetaTitle || dfMetaConfig?.MetaTitle
  const metaKeyWords = data?.MetaKeyWords || dfMetaConfig?.MetaKeyWords
  const metaDescription =
    data?.MetaDescription || data?.Description || dfMetaConfig?.MetaDescription
  const metaUrl = dfMetaConfig?.MetaUrl
  const canonicalUrl = data?.CanonicalURL || window.location.href
  const noindex = data?.NoIndex || false
  const appTitle = HCF.getCF("title")
  const appLogo = `${HCF.getCF("baseUrl")}/logo/logo-sigo.png`
  const seoInfo =
    HUtils.get(dataFirstSetting, "DefaultValues.websiteSetting.SEO") || {}
  const { telephone, supportEmail, socialLinks, businessInfo, founderInfo } =
    seoInfo
  return (
    <Helmet>
      <title>{metaTitle}</title>
      <link rel="canonical" href={canonicalUrl} />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeyWords} />
      <meta property="fb:app_id" content={HCF.getCF("facebookAppId")}></meta>
      <meta property="fb:admins" content={HCF.getCF("facebookAdmins")}></meta>
      <meta property="og:url" content={window.location.href} />
      <meta
        property="og:image"
        content={window.location.origin + "/img/logo-1024.png"}
      />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="article" />
      <meta property="og:locale" content="vi_VN" />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          name: appTitle,
          alternateName: "SIGO",
          url: metaUrl,
          logo: appLogo,
          sameAs: socialLinks,
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "LocalBusiness",
          name: appTitle,
          image: appLogo,
          "@id": metaUrl,
          url: metaUrl,
          telephone: telephone,
          email: supportEmail,
          description: dfMetaConfig?.MetaDescription,
          numberOfEmployees: businessInfo?.numberOfEmployees,
          hasMap: businessInfo?.hasMap,
          sameAs: businessInfo?.news,
          additionalType: businessInfo?.identifications,
          founder: {
            "@type": "Person",
            name: founderInfo?.name,
            URL: founderInfo?.url,
            Address: founderInfo?.address,
          },
          geo: {
            "@type": "GeoCoordinates",
            latitude: "10.7954327",
            longitude: "106.7361522",
          },
          openingHoursSpecification: {
            "@type": "OpeningHoursSpecification",
            dayOfWeek: [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ],
            opens: "00:00",
            closes: "24:00",
          },
          areaServed: {
            "@type": "Country",
            url: "https://www.wikidata.org/wiki/Q881",
            name: "Việt Nam",
          },
          department: {
            "@type": "LocalBusiness",
            name: appTitle,
            image: appLogo,
            telephone: telephone,
          },
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          url: metaUrl,
          logo: appLogo,
          name: "SIGO",
          contactPoint: [
            {
              "@type": "ContactPoint",
              telephone: "1900 99 99 19",
              contactType: "Customer Service",
              areaServed: "VN",
              availableLanguage: "Vietnamese",
            },
            {
              "@type": "ContactPoint",
              telephone: "02888889399",
              contactType: "Outbound Calls",
              areaServed: "VN",
              availableLanguage: "Vietnamese",
            },
          ],
        })}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "Person",
          name: founderInfo?.name,
          url: founderInfo?.url,
          image: founderInfo?.image,
          sameAs: founderInfo?.socials,
          jobTitle: "CEO",
          worksFor: {
            "@type": "Organization",
            name: businessInfo?.name,
          },
        })}
      </script>
      {noindex && <meta name="robots" content="noindex" />}
    </Helmet>
  )
}
export default connector(MyUIHelmet)
