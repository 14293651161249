import { HLink, HUtils } from "@macashipo/mlib"
import classNames from "classnames"
import { Button } from "reactstrap"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const HomeHelps = props => {
  const { data, configMoreInfo } = props

  const _url = HUtils.get(data, `${homeSlidesFields.fOneSlideMoreInfo}.url`)
  const img = data?.[homeSlidesFields.fOneSlideImageUrl]
  const title = data?.[homeSlidesFields.fOneSlideTitle]
  const description = data?.[homeSlidesFields.fOneSlideDescription]
  const moreInfo = data?.[homeSlidesFields.fOneSlideMoreInfo] || {}
  const btnConfig = {
    ...HUtils.get(configMoreInfo, "websiteSetting.configBtn4EachCard"),
    ...HUtils.get(moreInfo, "websiteSetting"), // config của từng card
  }
  const onHandleBtn = () => {
    if (_url) {
      HLink.openUrl(_url, "_blank")
    }
  }
  return (
    <Wrap className="helps-item-wrap">
      <img className="lazy" src={img} data-original={img} alt={title} />
      {title && (
        <div
          className="title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></div>
      )}
      {description && (
        <div
          className="description html_content"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      )}
      {btnConfig?.canShow && (
        <Button
          color={btnConfig?.color}
          className={btnConfig?.className}
          style={btnConfig?.style}
          onClick={onHandleBtn}
          disabled={!_url ? true : false}
        >
          {btnConfig?.title}
        </Button>
      )}
    </Wrap>
  )
}
const Wrap = styled.div`
  & .title {
    color: var(--color-text-000);
    font-size: 20px;
    font-weight: 700;
  }
  & .description {
    font-size: 16px;
    color: #5a5a5a;
    margin-top: 15px;
    max-width: 90%;
  }
  & button {
    margin-top: 20px;
    padding: 7px 20px;
  }
  @media (max-width: 767px) {
    & .description {
      max-width: 100%;
    }
  }
`
export default HomeHelps
