import React, { useEffect, useState } from "react"
import { HLink, MyIcon, MyUI } from "@macashipo/mlib"
import { categoryFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"
import classNames from "classnames"
import NoImage from "src/modules/app_sigo/assets/imgs/no-image.jpg"
import { Button, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap"
import { MdOutlineClose } from "react-icons/md"

const HomeCarCard = props => {
  const { data, config } = props
  let detailUrl = data?.[categoryFields.fSlug]
  const [open, setOpen] = useState(false)
  /**New version */
  if (window.location.search) {
    detailUrl = detailUrl + window.location.search
  }
  const onToggleDropdown = () => {
    setOpen(prev => !prev)
  }
  const renderImgSlider = () => {
    const imgList = data?.[categoryFields.fImageUrls] || []
    const dfImage = config?.defaultImage || NoImage
    const _slideConfig = config?.slideOptions || {}

    return (
      <WrapSlider>
        <MyUI
          type="slide_card"
          config={{
            only_image: true,
            slide_options: {
              items: 1,
              loop: false,
              dots: true,
              rewind: false,
              nav: true,
              lazyLoad: true,
              ..._slideConfig,
            },
            default_image: dfImage,
          }}
          classNameWrap="wrap-home-slide"
          data={{
            SlideImages: [...imgList],
            Url: detailUrl,
            UrlTarget: "_blank",
          }}
        />
        {config?.showAddWishList && (
          <div className="heart">
            <MyIcon data="type:ic_heart" />
          </div>
        )}
      </WrapSlider>
    )
  }
  const renderPriceDetail = () => {
    const rentalPrice = data?.[categoryFields.fPDRentalPrice]
    const rentalPriceDetail = data?.[categoryFields.fRentalPriceDetails]
    if (rentalPriceDetail && rentalPriceDetail?.length > 0) {
      return (
        <Dropdown
          isOpen={open}
          toggle={() => {
            onToggleDropdown()
          }}
          direction="right"
        >
          <DropdownToggle color="total-price-dropdown-toggle">
            <div className={classNames("rental-price", open && "underline")}>
              <span className="total">Tổng </span>
              {rentalPrice}
            </div>
          </DropdownToggle>
          <DropdownMenu className="price-detail-dropdown-menu">
            <div className="header">
              <Button color="hide-dropdown" onClick={onToggleDropdown}>
                <MdOutlineClose />
              </Button>
              <div className="title">Chi tiết giá</div>
            </div>
            <hr />
            <div className="content">
              {rentalPriceDetail &&
                rentalPriceDetail?.length > 0 &&
                rentalPriceDetail.map((v, i) => {
                  return (
                    <div className="one-price-detail">
                      <span className={v?.Information ? "" : ""}>
                        {v?.Title}
                        {/* {v?.Information && (
                          <MyUI
                            type="popover"
                            overlay={<div>{v.Information}</div>}
                          >
                            <i className="flaticon-information mx-1 cursor-pointer" />
                          </MyUI>
                        )} */}
                      </span>
                      <span className="">
                        <div
                          dangerouslySetInnerHTML={{ __html: v?.PriceText }}
                        ></div>
                      </span>
                    </div>
                  )
                })}
              <hr />
              <div className="one-price-detail amount">
                <span className="">Tổng trước thuế</span>
                <span className="">{rentalPrice}</span>
              </div>
            </div>
          </DropdownMenu>
        </Dropdown>
      )
    }
    return (
      <div className="rental-price">
        <span className="total">Tổng </span>
        {rentalPrice}
      </div>
    )
  }
  const renderContentItem = () => {
    const name = data?.[categoryFields.fPDName]
    const rate = data?.[categoryFields.fPDRate]
    const address = data?.[categoryFields.fAddress]
    const distance = data?.[categoryFields.fPDDistance]
    const freeDay = data?.[categoryFields.fFreeDaySegment_Text]
    /** Khi Hiển thị tổng trước thuế khác true */
    const rentalPriceOriginalByDay =
      data?.[categoryFields.fRentalPriceByDayOriginal]
    const rentalPriceByDay = data?.[categoryFields.fRentalPriceByDay]
    /** Khi hiển thị tổng trước thuế = true */
    const rentalPriceOriginal = data?.[categoryFields.fPDRentalPriceOriginal]
    const rentalPrice = data?.[categoryFields.fPDRentalPrice]
    const rentalPriceDetail = data?.[categoryFields.fRentalPriceDetails]
    let showOriginal =
      rentalPriceOriginalByDay && rentalPriceOriginalByDay !== rentalPriceByDay
        ? true
        : false
    // if (config?.isShowAmount) {
    //   showOriginal =
    //     rentalPriceOriginal && rentalPriceOriginal !== rentalPrice
    //       ? true
    //       : false
    // } else {
    //   showOriginal =
    //     rentalPriceOriginalByDay &&
    //     rentalPriceOriginalByDay !== rentalPriceByDay
    //       ? true
    //       : false
    // }

    return (
      <WrapContentItem id="one-car-item" className={"show-price-by-day"}>
        <div className="wrap-name">
          <a
            className="name"
            href={detailUrl}
            target="_blank"
            rel="noreferrer nofollow"
            title={name}
          >
            <h3>{name}</h3>
          </a>
          {rate && (
            <div className="rate">
              <i className="fa fa-star"></i>
              <span>{rate}</span>
            </div>
          )}
        </div>
        {address && (
          <div className="wrap-sub-content wrap-address">{address}</div>
        )}
        {freeDay && <div className="wrap-sub-content">{freeDay}</div>}
        {/* <div
          className={classNames(
            "wrap-price show-amount",
            !showOriginal && "flex-end"
          )}
          id="amount-price"
        >
          {showOriginal && (
            <div
              className={classNames(
                "original",
                rentalPriceDetail && rentalPriceDetail?.length > 0 && "mt-1px"
              )}
            >
              {rentalPriceOriginal}
            </div>
          )}
        </div> */}
        <div
          className={classNames("wrap-price", !showOriginal && "flex-end")}
          id="price-by-day"
        >
          {showOriginal && (
            <div className="original">{rentalPriceOriginalByDay}</div>
          )}
          {rentalPriceByDay && (
            <div className="rental-price">
              {rentalPriceByDay}
              <span>/ngày</span>
            </div>
          )}
        </div>
      </WrapContentItem>
    )
  }
  return (
    <Wrap className="one-car col-xxl-2-custom col-md-3 col-mm-4 col-ms-6 col-xs-12 col-xxs-12">
      {renderImgSlider()}
      {renderContentItem()}
    </Wrap>
  )
}
const WrapSlider = styled.div`
  position: relative;
  max-height: 234px;
  min-height: 234px;
  height: 234px;

  & img {
    border-radius: 10px;
    max-height: 234px;
    height: 234px;
    object-fit: cover;
  }
  & .empty-img {
    text-align: center;
  }
  & .owl-carousel {
    z-index: 0 !important;
  }
  & .owl-dots {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    max-width: 80px;
    overflow: hidden;
    bottom: 10px !important;
    & .owl-dot {
      width: 4px !important;
      height: 4px !important;
      min-width: 4px !important;
      background-color: var(--color-text-e1e1e1) !important;
      margin-right: 5px !important;
    }
    & .owl-dot.active {
      background-color: var(--color-text-fff) !important;
      width: 6px !important;
      height: 6px !important;
      min-width: 6px !important;
    }
  }
  & .owl-nav {
    i:before {
      font-size: 14px;
    }
  }

  & .owl-nav {
    & .owl-prev {
      left: 5px !important;
    }
    & .owl-next {
      right: 5px !important;
    }
  }

  & .heart {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    cursor: pointer;
  }
  & .wrap-item {
    cursor: pointer;
  }
  @media (max-width: 1199px) {
    & .wrap-item {
      .owl-nav {
        opacity: 0;
        pointer-events: none;
        touch-action: none;
        transition: all 0.25s;
        visibility: hidden;
      }
    }
    & .wrap-item:hover {
      .owl-nav {
        opacity: 0 !important;
        pointer-events: none !important;
        touch-action: none !important;
        visibility: hidden !important;
        & .disabled {
          display: none !important;
          opacity: 0 !important;
        }
      }
    }
  }
`
const WrapContentItem = styled.div`
  padding: 10px 0;
  & .wrap-name {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    & .name {
      color: var(--color-text-000);
      max-width: calc(100% - 60px);
      & h3 {
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    & .rate {
      font-size: 16px;
      color: var(--color-text-000);
      i {
        color: var(--color-text-000);
        margin-right: 5px;
        font-size: 17px;
      }
    }
  }
  & .wrap-sub-content {
    font-size: 14px;
    color: #6b6a6a;
  }
  & .wrap-price {
    display: none;
    align-items: center;
    & .original {
      color: #828282;
      font-size: 12px;
      text-decoration: line-through;
      padding-right: 5px;
      &.mt-1px {
        margin-top: 1px;
      }
    }
    & .rental-price {
      color: var(--color-text-000);
      font-weight: 700;
      font-size: 14px;
      & span.total {
        font-weight: 400;
      }
      &.underline {
        text-decoration: underline;
      }
    }
  }
  &.show-price-amount {
    & #amount-price {
      display: flex;
    }
    & #price-by-day {
      display: none;
    }
  }
  &.show-price-by-day {
    & #amount-price {
      display: none;
    }
    & #price-by-day {
      display: flex;
    }
  }
  & .btn-total-price-dropdown-toggle {
    padding: 0;
    border: none;
    box-shadown: none;
  }
  & .btn-total-price-dropdown-toggle:hover {
  }
  & .price-detail-dropdown-menu {
    padding: 20px;
    background-color: var(--color-text-fff);
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.28);
    width: 400px;
    border-radius: 20px;
    display: unset;
    opacity: 0;
    visibility: hidden;
    // animation: examplehide 400ms ease;
    // top: 0 !important;
    & .header {
      position: relative;
      text-align: center;
      & .btn-hide-dropdown {
        padding: 0;
        border: none;
        box-shadown: none;
        color: var(--color-text-222222);
        font-size: 16px;
        position: absolute;
        top: 0;
        left: 0;
      }
      & .title {
        font-size: 17px;
        font-weight: 600;
        color: var(--color-text-000);
      }
    }
    & > hr {
      margin: 20px -24px 0 !important;
      border-top: 1px solid #ddd;
    }
    & .content {
      padding: 24px 0 0;
      & .one-price-detail {
        font-size: 14px;
        padding-bottom: 10px;
        color: var(--color-text-111);
        display: flex;
        justify-content: space-between;
      }
      & .one-price-detail.amount {
        font-weight: 600;
        font-size: 15px;
        padding-bottom: 0px;
      }
      & > hr {
        margin-top: 10px !important;
      }
    }
  }
  & .price-detail-dropdown-menu.show {
    opacity: 1;
    visibility: visible;
    // animation: exampleshow 400ms ease;
    top: 25px !important;
  }
  // @keyframes examplehide {
  //   0% {
  //     top: -235px;
  //   }

  //   100% {
  //     top: -180px;
  //   }
  // }
  // @keyframes exampleshow {
  //   0% {
  //     top: -180px;
  //   }

  //   100% {
  //     top: -235px;
  //   }
  // }
  & .price-detail-dropdown-menu[x-placement="left-start"] {
    left: 270px !important;
  }
  & .price-detail-dropdown-menu[x-placement="right-start"] {
    left: -100px !important;
    transform: translate3d(100px, 4px, 0px) !important;
  }
  @media (max-width: 1499px) {
    & .price-detail-dropdown-menu[x-placement="left-start"] {
      left: 300px !important;
    }
  }
  @media (max-width: 1199px) {
    & .price-detail-dropdown-menu[x-placement="left-start"] {
      left: 250px !important;
    }
  }
  @media (max-width: 1024px) {
    & .price-detail-dropdown-menu[x-placement="left-start"] {
      left: 235px !important;
    }
  }
  @media (max-width: 576px) {
    & .price-detail-dropdown-menu {
      width: calc(100vw - 10px);
    }
    & .price-detail-dropdown-menu[x-placement="left-start"] {
      left: 0px !important;
    }
  }
`
const Wrap = styled.div`
  &.one-car {
    margin-bottom: 15px;
  }
  @media (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
`
// const Wrap2 = styled.div`
//   & .xe:not(.taixe) {
//     img {
//       width: 100%;
//       max-height: 246px;
//       height: 230px;
//       object-fit: cover;
//     }
//   }
//   @media (max-width: 1023px) {
//     & .xe:not(.taixe) {
//       img {
//         max-height: 230px;
//         height: 230px;
//       }
//     }
//   }
// `
export default HomeCarCard
