import styled from "styled-components"
import Odometer from "react-odometerjs"
import GoogleMapReact from "google-map-react"
import { useEffect, useState } from "react"
import RangeSlider from "react-range-slider-input"
import "react-range-slider-input/dist/style.css"
import { IoSearchOutline } from "react-icons/io5"
import { HCF, HUtils, MyIcon, MyUI } from "@macashipo/mlib"
import { Button, Dropdown, DropdownMenu, Input } from "reactstrap"
import { MdOutlineClose } from "react-icons/md"
import { MyModal } from "src/core/components"

interface AnyReactComponentProps {
  text?: string
  lat: number
  lng: number
  tooltip?: any
  $hover?: any
  moreConfig?: any
}

const AnyReactComponent = ({
  text,
  lat,
  lng,
  tooltip,
  $hover,
  ...props
}: AnyReactComponentProps) => {
  const { moreConfig = {} } = props
  const [isHover, setIsHover] = useState(false)

  return (
    <WrapMaker
      onMouseLeave={() => {
        setIsHover(false)
      }}
      onMouseEnter={() => {
        setIsHover(true)
      }}
      className={`${$hover || isHover ? "is-hover" : ""}`}
    >
      <div>{text}</div>
    </WrapMaker>
  )
}
const WrapMaker = styled.div`
  height: 28px;
  padding: 0px 8px;
  border-radius: 28px;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18), 0px 2px 4px 0px rgba(0, 0, 0, 0.18),
    0px 0px 0px 1px rgba(0, 0, 0, 0.08);
  color: var(--color-text-000);
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
`

const UIOdometer = props => {
  const { defaultValue } = props
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    if (defaultValue != value) {
      setValue(defaultValue)
    }
  }, [defaultValue])
  return <Odometer value={value} format="(.ddd),dd" />
}

const UIChangeDate = props => {
  const { defaultValue, fnChangeValue } = props
  const [curValue, setCurValue] = useState(defaultValue)

  const handleIncrease = () => {
    let newValue = curValue + 1
    if (newValue <= 30) {
      setCurValue(newValue)
    }
  }
  const handleDecrease = () => {
    let newValue = curValue - 1
    if (newValue >= 1) {
      setCurValue(newValue)
    }
  }

  return (
    <div className="wrap-dropdown-content">
      <div className="content">
        <div className="d-flex wrap-btn">
          <div className="btn-circle" onClick={handleDecrease}>
            <MyIcon data="type:ic_minus" />
          </div>
          <Input
            type="number"
            min={1}
            max={30}
            value={curValue}
            onChange={e => {
              let newValue = parseInt(e.target?.value)
              if (newValue >= 1 && newValue <= 30) {
                setCurValue(parseInt(e.target?.value))
              }
            }}
          />
          <div className="btn-circle" onClick={handleIncrease}>
            <MyIcon data="type:ic_plus" />
          </div>
        </div>
      </div>
      <hr />
      <div className="footer">
        <Button
          color="primary"
          onClick={() => {
            fnChangeValue && fnChangeValue(curValue)
            MyModal.hideModalComponent()
          }}
        >
          Cập nhật
        </Button>
      </div>
    </div>
  )
}

const MUIIncomeAndMap = props => {
  const { data } = props
  const [amountIncome, setAmountIncome] = useState(1800000)
  const [slider, setSliderValue] = useState([1, 1])

  const getCenter = data => {
    let totalLat = 0
    let totalLng = 0
    if (data?.length > 0) {
      const count = data?.length
      data.forEach(item => {
        totalLat += item?.lat
        totalLng += item?.lng
      })

      return {
        lat: totalLat / count,
        lng: totalLng / count,
      }
    }
    return
  }
  const handleChangeNumberOfDate = () => {
    // console.log("devtest - onThumbDragEnd", amountIncome)
    // setAmountIncome(1800*slider[1])
  }
  const handleChangeDate = value => {
    setSliderValue([1, value])
    setAmountIncome(1800000 * value)
  }
  const onOpenModal = () => {
    MyModal.showFromComponent(
      <UIChangeDate
        defaultValue={slider[1]}
        fnChangeValue={handleChangeDate}
      />,
      {
        size: "default",
        title: "Bao nhiêu ngày",
        modalClassName: "default modal-filter modal-change-number-of-date",
        onScrollContent: true,
      }
    )
  }

  const renderChangeNumberOfDate = () => {
    return (
      <WrapChangeDate>
        <div className="wrap-estimated">
          <div className="wrap-toggle">
            <span onClick={onOpenModal}>{slider[1]} ngày</span>
            <span className="remove-user-select">
              {" "}
              với ước tính giá 1.800k vnđ mỗi ngày
            </span>
          </div>
        </div>
      </WrapChangeDate>
    )
  }
  const renderIncome = () => {
    return (
      <WrapIncome>
        <div className="title">Cho thuê xe trên SIGO.</div>
        <div className="sub-title">Bạn có thể kiếm được</div>
        <div className="wrap-estimated-income d-flex align-items-center">
          <UIOdometer defaultValue={amountIncome} />
          <div style={{ marginTop: "-5px", paddingLeft: "16px" }}> VNĐ</div>
        </div>
        {renderChangeNumberOfDate()}
        <RangeSlider
          thumbsDisabled={[true, false]}
          value={slider}
          min={1}
          max={30}
          rangeSlideDisabled={true}
          step={1}
          onInput={value => {
            setSliderValue(value)
            setAmountIncome(1800000 * value[1])
          }}
          onThumbDragEnd={() => {
            // Xử lý gọi api khi user kết thúc việc kéo slider thay đổi giá trị
            handleChangeNumberOfDate()
          }}
        />
        <Button color="underline-black" onClick={() => {}} className="mt-4">
          Tìm hiểu cách chúng tôi ước tính thu nhập của bạn
        </Button>
        <div className="wrap-search">
          <IoSearchOutline />
          <div className="wrap-text">
            <div className="w-600">Thành phố Hồ Chí Minh</div>
            <div className="other-info">Xe 4 chỗ - Sedan</div>
          </div>
        </div>
      </WrapIncome>
    )
  }
  const renderMap = () => {
    const points = [
      {
        lat: 10.809375,
        lng: 106.678428,
        price: "116.000đ",
      },
      {
        lat: 10.814152,
        lng: 106.678554,
        price: "216.000đ",
      },
      {
        lat: 10.811707,
        lng: 106.678554,
        price: "1.516.000đ",
      },
      {
        lat: 10.809262,
        lng: 106.678597,
        price: "236.000đ",
      },
    ]
    const center = getCenter(points)
    const defaultProps = {
      center: center,
      zoom: 17,
    }
    if (points?.length) {
      return (
        <WrapMap className="wrap-map">
          <div className="cover-map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: HCF.getCF("GooglePlaceApiKey"),
                language: "vi",
                libraries: "places",
              }}
              center={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              options={{
                scrollwheel: false,
                fullscreenControl: false,
              }}
            >
              {points?.map((v, i) => {
                return (
                  <AnyReactComponent
                    lat={v?.lat}
                    lng={v?.lng}
                    text={v?.price}
                  />
                )
              })}
            </GoogleMapReact>
          </div>
        </WrapMap>
      )
    }
    return <></>
  }
  return (
    <Wrap className="mui-income-map d-flex container">
      {renderIncome()}
      {renderMap()}
    </Wrap>
  )
}
const Wrap = styled.div`
  align-items: center;
  .odometer.odometer-auto-theme,
  .odometer.odometer-theme-default {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
  }
  .odometer.odometer-auto-theme .odometer-digit,
  .odometer.odometer-theme-default .odometer-digit {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    position: relative;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer,
  .odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer {
    display: inline-block;
    vertical-align: middle;
    *vertical-align: auto;
    *zoom: 1;
    *display: inline;
    visibility: hidden;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner,
  .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner {
    text-align: left;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-ribbon,
  .odometer.odometer-theme-default .odometer-digit .odometer-ribbon {
    display: block;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner,
  .odometer.odometer-theme-default .odometer-digit .odometer-ribbon-inner {
    display: block;
    -webkit-backface-visibility: hidden;
  }
  .odometer.odometer-auto-theme .odometer-digit .odometer-value,
  .odometer.odometer-theme-default .odometer-digit .odometer-value {
    display: block;
    -webkit-transform: translateZ(0);
  }
  .odometer.odometer-auto-theme
    .odometer-digit
    .odometer-value.odometer-last-value,
  .odometer.odometer-theme-default
    .odometer-digit
    .odometer-value.odometer-last-value {
    position: absolute;
  }
  .odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner,
  .odometer.odometer-theme-default.odometer-animating-up
    .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
  }
  .odometer.odometer-auto-theme.odometer-animating-up.odometer-animating
    .odometer-ribbon-inner,
  .odometer.odometer-theme-default.odometer-animating-up.odometer-animating
    .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner,
  .odometer.odometer-theme-default.odometer-animating-down
    .odometer-ribbon-inner {
    -webkit-transform: translateY(-100%);
    -moz-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  .odometer.odometer-auto-theme.odometer-animating-down.odometer-animating
    .odometer-ribbon-inner,
  .odometer.odometer-theme-default.odometer-animating-down.odometer-animating
    .odometer-ribbon-inner {
    -webkit-transition: -webkit-transform 2s;
    -moz-transition: -moz-transform 2s;
    -ms-transition: -ms-transform 2s;
    -o-transition: -o-transform 2s;
    transition: transform 2s;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  .odometer.odometer-auto-theme,
  .odometer.odometer-theme-default {
    font-family: "Helvetica Neue", sans-serif;
    line-height: 1.1em;
  }
  .odometer.odometer-auto-theme .odometer-value,
  .odometer.odometer-theme-default .odometer-value {
    text-align: center;
  }
  @media (max-width: 1099px) {
    flex-direction: column;
    justify-content: center;
  }
`
const WrapIncome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  & .title {
    font-size: 40px;
    font-weight: 700;
    color: var(--color-text-info);
    text-align: center;
    margin-bottom: 0;
    line-height: 55px;
  }
  & .sub-title {
    color: var(--color-text-000);
    font-weight: 600;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
  }
  & .wrap-estimated-income {
    margin-top: 2rem;
  }
  & .wrap-estimated-income,
  & .odometer {
    font-size: 50px;
    color: var(--color-text-000);
    font-weight: 700;
  }
  & .range-slider {
    margin-top: 2rem;
    max-width: 450px;
    height: 4px;
    border-radius: 2px;
  }
  & .range-slider__range {
    border-radius: 6px;
  }
  & .range-slider .range-slider__thumb {
    width: 30px;
    height: 30px;
    border: 1px solid #d0cccc;
    background-color: #fff;
    box-shadow: 0px 4px 15px 0px #00000026;
    &:focus {
      border: 2px solid #000000;
    }
  }
  & .range-slider__thumb[data-lower] {
    width: 0;
    box-shadow: none;
    border: none;
  }
  & .range-slider .range-slider__range {
    background: #000;
    height: 4px;
    pointer-events: none;
  }
  & .wrap-search {
    margin-top: 1.5rem;
    display: flex;
    padding: 0.75rem 1.5rem;
    border: 1px solid #c5c5c5;
    border-radius: 40px;
    align-items: center;
    width: 350px;
    & svg {
      font-size: 32px;
      color: var(--mau1);
    }
    & .wrap-text {
      color: var(--color-text-000);
      padding-left: 0.75rem;
      & .w-600 {
        font-weight: 600;
      }
    }
  }
  @media (max-width: 1099px) {
    width: 100%;
  }
  @media (max-width: 767px) {
    & .title {
      font-size: 32px;
      line-height: 40px;
    }
    & .sub-title {
      font-size: 28px;
      line-height: 36px;
      text-align: center;
    }
    & .wrap-estimated-income {
      margin-top: 1.5rem;
    }
    & .wrap-estimated-income,
    & .odometer {
      font-size: 34px;
    }
    & .range-slider {
      margin-top: 1.5rem;
    }
    & .wrap-search {
      margin-top: 1rem;
    }
    & .btn.btn-underline-black {
      white-space: unset;
    }
  }
`
const WrapChangeDate = styled.div`
  & .wrap-estimated {
    margin-top: 0.75rem;
    text-align: center;
    & span {
      font-size: 20px;
      color: rgb(101, 101, 101);
    }
    & span:not(.remove-user-select) {
      cursor: pointer;
      font-weight: 700;
      color: var(--color-text-000);
      text-decoration: underline;
    }
    & .remove-user-select {
      cursor: context-menu;
      user-select: none;
      pointer-event: none;
    }
  }
  & .wrap-dropdown-content {
    padding: 20px;
    background-color: var(--color-text-fff);
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.28);
    width: 400px;
    border-radius: 20px;
    display: unset;
    opacity: 0;
    visibility: hidden;
    & .header {
      position: relative;
      text-align: center;
      & .btn-hide-dropdown {
        padding: 0;
        border: none;
        box-shadown: none;
        color: var(--color-text-222222);
        font-size: 16px;
        position: absolute;
        top: 0;
        left: 0;
      }
      & .title {
        font-size: 17px;
        font-weight: 600;
        color: var(--color-text-000);
      }
    }
    & > hr {
      margin: 20px -24px 0 !important;
      border-top: 1px solid #ddd;
    }
    & .content {
      padding: 24px 0 0;
      & .one-price-detail {
        font-size: 14px;
        padding-bottom: 10px;
        color: var(--color-text-111);
        display: flex;
        justify-content: space-between;
      }
      & .one-price-detail.amount {
        font-weight: 600;
        font-size: 15px;
        padding-bottom: 0px;
      }
      & > hr {
        margin-top: 10px !important;
      }
    }
    & .wrap-btn {
      justify-content: center;
      align-items: center;
    }
    & .btn-circle {
      padding: 5px;
      border: 1px solid #6a6a6a;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      text-align: center !important;
      display: inline-flex !important;
      align-items: center !important;
      justify-content: center !important;
      cursor: pointer;
      & svg {
        width: 12px;
        height: 12px;
        display: block;
        fill: #6a6a6a;
      }
      &:hover {
        border-color: var(--color-text-000);
        & svg {
          fill: var(--color-text-000);
        }
      }
    }
    input {
      width: 140px;
      font-size: 32px;
      color: var(--color-text-000);
      height: 48px;
      text-align: center;
      font-weight: 600;
      margin: 0 1.5rem;
    }
    & .footer {
      padding-top: 1.25rem;
      & .btn {
        width: 100%;
      }
    }
  }
  & .wrap-dropdown-content.show {
    opacity: 1 !important;
    visibility: visible;
    pointer-events: unset !important;
    top: 25px !important;
  }
  & .title {
    line-height: unset;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  @media (max-width: 767px) {
    & .wrap-estimated {
      text-align: center;
      & span {
        font-size: 16px;
      }
    }
  }
`
const WrapMap = styled.div`
  margin-left: 1rem;
  &.wrap-map {
    display: flex;
    border: 1px solid #d1d1d1;
    height: 500px;
    width: 50%;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
  & .cover-map {
    flex: 1;
    margin-right: -2px;
    margin-left: -2px;
    height: calc(100% + 4px);
  }
  @media (max-width: 1099px) {
    margin-left: 0;
    margin-top: 2rem;
    &.wrap-map {
      width: 100%;
    }
  }
`
export default MUIIncomeAndMap
