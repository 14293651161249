import React from "react"
import { MyIconHelpers } from "@macashipo/mlib"
import MC_ic_self_driving from "./Types/ic_self_driving"
import MC_ic_long_term_rent from "./Types/ic_long_term_rent"
import MC_ic_has_driver from "./Types/ic_has_driver"

const MI_ic_location_pin = React.lazy(() => import("./Types/ic_location_pin"))
const MI_ic_heart = React.lazy(() => import("./Types/ic_heart"))
const MI_ic_vehicle_type = React.lazy(() => import("./Types/ic_vehicle_type"))
const MI_ic_vehicle_make = React.lazy(() => import("./Types/ic_vehicle_make"))
const MI_ic_chevron_down = React.lazy(() => import("./Types/ic_chevron_down"))
const MI_ic_refresh = React.lazy(() => import("./Types/ic_refresh"))
const MI_ic_filter_category = React.lazy(
  () => import("./Types/ic_filter_category")
)
const MI_ic_facebook = React.lazy(() => import("./Types/ic_facebook"))
const MI_ic_youtube = React.lazy(() => import("./Types/ic_youtube"))
const MI_ic_tiktok = React.lazy(() => import("./Types/ic_tiktok"))
const MI_ic_instagram = React.lazy(() => import("./Types/ic_instagram"))
const MI_ic_taxi_download = React.lazy(() => import("./Types/ic_taxi_download"))
const MI_ic_qrdownload = React.lazy(() => import("./Types/ic_qrdownload"))
const MI_ic_discount = React.lazy(() => import("./Types/ic_discount"))
const MI_ic_car_insurance = React.lazy(() => import("./Types/ic_car_insurance"))
const MI_ic_documents = React.lazy(() => import("./Types/ic_documents"))
const MI_ic_mortgage = React.lazy(() => import("./Types/ic_mortgage"))
const MI_ic_car_flag = React.lazy(() => import("./Types/ic_flag"))
const MI_ic_insurance = React.lazy(() => import("./Types/ic_insurance"))
const MI_ic_gg_map_location_pin = React.lazy(
  () => import("./Types/ic_gg_map_location_pin")
)
const MI_ic_minus = React.lazy(() => import("./Types/ic_minus"))
const MI_ic_plus = React.lazy(() => import("./Types/ic_plus"))
// const MC_ic_self_driving = React.lazy(() => import("./Types/ic_self_driving"))
// const MC_ic_long_term_rent = React.lazy(
//   () => import("./Types/ic_long_term_rent")
// )
// const MC_ic_has_driver = React.lazy(() => import("./Types/ic_has_driver"))
const List = {
  ic_location_pin: MI_ic_location_pin,
  ic_heart: MI_ic_heart,
  ic_vehicle_type: MI_ic_vehicle_type,
  ic_vehicle_make: MI_ic_vehicle_make,
  ic_chevron_down: MI_ic_chevron_down,
  ic_refresh: MI_ic_refresh,
  ic_filter_category: MI_ic_filter_category,
  ic_facebook: MI_ic_facebook,
  ic_youtube: MI_ic_youtube,
  ic_tiktok: MI_ic_tiktok,
  ic_instagram: MI_ic_instagram,
  ic_taxi_download: MI_ic_taxi_download,
  self_driving: MC_ic_self_driving,
  long_term_rent: MC_ic_long_term_rent,
  has_driver: MC_ic_has_driver,
  ic_qrdownload: MI_ic_qrdownload,
  discount: MI_ic_discount,
  car_insurance: MI_ic_car_insurance,
  documents: MI_ic_documents,
  mortgage: MI_ic_mortgage,
  ic_flag: MI_ic_car_flag,
  ic_insurance: MI_ic_insurance,
  ic_gg_map_location_pin: MI_ic_gg_map_location_pin,
  ic_minus: MI_ic_minus,
  ic_plus: MI_ic_plus,
}
const MyIconOne = {
  init() {
    MyIconHelpers.addTypesFromListLazy(List)
  },
}

export default MyIconOne
