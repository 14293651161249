import { HLink, HUtils } from "@macashipo/mlib"
import classNames from "classnames"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import styled from "styled-components"

const HomeAdvantageCard = props => {
  const { data, configMoreInfo } = props

  const _url = HUtils.get(data, `${homeSlidesFields.fOneSlideMoreInfo}.url`)
  const img = data?.[homeSlidesFields.fOneSlideImageUrl]
  const title = data?.[homeSlidesFields.fOneSlideTitle]
  const description = data?.[homeSlidesFields.fOneSlideDescription]
  const itemConfig = HUtils.get(configMoreInfo, "websiteSetting.itemConfig")

  const onHandleClick = () => {
    if (_url) {
      HLink.openUrl(_url, "_blank")
    }
  }
  return (
    <Wrap
      onClick={onHandleClick}
      className={classNames(_url && "cursor-pointer")}
      bgColor={itemConfig?.bgColor}
    >
      <div className="wrap-img">
        <div className="bg-img"></div>
        <img className="lazy" src={img} data-original={img} alt={title} />
      </div>
      {title && (
        <h3 className="title" dangerouslySetInnerHTML={{ __html: title }}></h3>
      )}
      {description && (
        <div
          className="description html_content"
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
      )}
    </Wrap>
  )
}
const Wrap: any = styled.div`
  &.cursor-pointer {
    cursor: pointer;
  }
  & .wrap-img {
    width: 100%;
    height: 475px;
    position: relative;
    & .bg-img {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: ${(props: any) =>
        props?.bgColor ? props?.bgColor : "#e8e8e8"};
      height: 50%;
      width: 50%;
      border-radius: 15px;
    }
    & img {
      border: 5px solid var(--color-text-fff);
      object-fit: cover;
      height: 455px;
      border-radius: 15px;
      position: absolute;
      width: calc(100% - 20px) !important;
    }
  }
  & .title {
    padding-top: 15px;
    font-size: 20px;
    color: var(--color-text-000);
    font-weight: 700;
  }
  & .description {
    color: #606060;
    font-size: 16px;
    padding-top: 15px;
  }
`
export default HomeAdvantageCard
