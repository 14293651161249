import React from "react"
import styled from "styled-components"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"
import { HLink, HUtils } from "@macashipo/mlib"
import classNames from "classnames"
import { squareBracketReplace } from "src/core/helpers/utils"
import classnames from "classnames"

const RowImageTextHoverBorderCard = props => {
  const { data, configMoreInfo } = props
  const img = data?.[homeSlidesFields.fOneSlideImageUrl]
  const title = data?.[homeSlidesFields.fOneSlideTitle]
  const description = data?.[homeSlidesFields.fOneSlideDescription]
  const moreInfo = data?.[homeSlidesFields.fOneSlideMoreInfo] || {}
  const btnConfig = {
    ...HUtils.get(configMoreInfo, "websiteSetting.configBtn4EachCard"),
    ...HUtils.get(moreInfo, "websiteSetting"), // config của từng card
  }
  const itemConfig = HUtils.get(configMoreInfo, "websiteSetting.itemConfig")
  const configHover = HUtils.get(configMoreInfo, "websiteSetting.configHover")
  const url = HUtils.get(btnConfig, "url")
  const classNameItem = itemConfig?.classNameMobile || itemConfig?.className
  const titleStyle = itemConfig?.titleStyleMobile || itemConfig?.titleStyle
  const imgStyle = itemConfig?.imgStyleMobile || itemConfig?.imgStyle
  const descriptionStyle =
    itemConfig?.descriptionStyleMobile || itemConfig?.descriptionStyle
  const rightStyle = itemConfig?.rightStyleMobile || itemConfig?.rightStyle

  const onHandleBtnLandingCard = () => {
    if (url) {
      HLink.openUrl(squareBracketReplace(url, data), "_blank")
    }
  }
  return (
    <Wrap
      className={classnames(
        "card-row-content-image row",
        classNameItem,
        itemConfig?.isHorizontalInMobile && "horizontal-mobile"
      )}
      style={itemConfig?.wrapStyle}
      configHover={configHover}
    >
      <div
        className={classNames(
          "wrap-image",
          itemConfig?.classNameLeft || "col-md-6"
        )}
        style={itemConfig?.wrapImgStyle}
      >
        {img && <img src={img} alt="" loading="lazy" style={imgStyle} />}
      </div>
      <div
        className={classNames(
          "wrap-content",
          itemConfig?.classNameRight || "col-md-4"
        )}
        style={rightStyle}
      >
        {title && (
          <div
            className="title"
            dangerouslySetInnerHTML={{ __html: title }}
            style={titleStyle}
          ></div>
        )}
        {description && (
          <div
            className="description html_content"
            dangerouslySetInnerHTML={{ __html: description }}
            style={descriptionStyle}
          ></div>
        )}
        {url && btnConfig?.canShow && (
          <button
            color={btnConfig?.color}
            className={classNames("mt-3 df-button", btnConfig?.className)}
            onClick={onHandleBtnLandingCard}
          >
            {btnConfig?.title}
          </button>
        )}
      </div>
    </Wrap>
  )
}
const Wrap: any = styled.div`
  padding: 10px;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: all 0.25s;
  margin-left: 0;
  margin-right: 0;
  & .title {
    color: var(--color-text-000);
    font-size: 20px;
    font-weight: 700;
    padding-top: 15px;
  }
  & .description {
    color: rgb(101, 101, 101);
    font-size: 16px;
    padding-top: 15px;
  }
  & .wrap-image {
    max-width: 250px;
    & > img {
      max-height: 500px;
      object-fit: contain;
    }
  }
  & .df-button {
    background-color: #fff;
    border: none;
    padding: 0;
    color: var(--color-text-000);
    text-decoration: underline 1px solid;
    font-size: 14px;
    font-weight: 600;
  }
  @media (max-width: 1199px) {
    & .wrap-image,
    & .wrap-content {
      width: 100%;
    }
  }
  @media (max-width: 767px) {
    padding: 0;
    &.horizontal-mobile {
      & .wrap-image {
        width: 40%;
        max-width: 250px;
        padding: 0;
        & > img {
          max-height: 500px;
          object-fit: contain;
        }
      }
      & .wrap-content {
        padding-left: 16px;
        width: 60%;
      }
    }
  }
`
export default RowImageTextHoverBorderCard
