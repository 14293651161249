import React, { useEffect, useState } from "react"
import classnames from "classnames"
import { HLink, MyCard, MyUI } from "@macashipo/mlib"
import OwlCarousel from "react-owl-carousel2"
import styled from "styled-components"
import { homeSlidesFields } from "src/modules/app_sigo/constants/fields"

const configKeySlide = {
  slide_options: "slide_options",
  url: "url",
  always_show_nav: "always_show_nav",
  only_image: "only_image",
  default_image: "default_image",
  slide_setting: "slide_setting",
  use_data: "use_data",
}

const MyUISlideCard = props => {
  const { data, className, config, fnList, classNameWrap, moreInfo } = props
  let slideImages = data?.[homeSlidesFields.fSlideImages]
  if (config?.[configKeySlide.use_data]) {
    slideImages = data
  }
  const configOptions = config?.[configKeySlide.slide_options] || {}
  const slideSetting = config?.[configKeySlide.slide_setting] || {}
  const [screenWidth, setScreenWidth] = useState(0)
  const options = {
    items: 3,
    nav: true,
    rewind: true,
    autoplay: false,
    dots: false,
    loop: false,
    navText: [
      "<i class='flaticon-left-arrow circel'/>",
      "<i class='flaticon-right-arrow circel'/>",
    ],
    ...configOptions,
  }
  const alwaysShowNav =
    config?.[configKeySlide.always_show_nav] && options?.loop

  const events = {
    onDragged: function (event) {},
    onChanged: function (event) {},
  }
  useEffect(() => {
    const ro = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
        const { left, top, width, height } = entry.contentRect
        if (entry.target == document.body) {
          setScreenWidth(width)
        }
      }
    })
    ro.observe(document.body)
    // eslint-disable-next-line
  }, [])
  const getIsCenter = () => {
    if (slideSetting?.needCenter) {
      const responsive = configOptions?.responsive || {}
      const keys = Object.keys(responsive)
      let config: any = {}
      let item = 0
      if (keys && keys?.length > 0) {
        keys.every((k, i) => {
          let configScreen = Number(k)
          let nextConfigScreen = -1
          if (i + 1 < keys?.length) {
            nextConfigScreen = Number(keys[i + 1])
          }
          if (
            screenWidth >= configScreen &&
            ((nextConfigScreen > -1 && screenWidth < nextConfigScreen) ||
              nextConfigScreen === -1)
          ) {
            config = responsive[k]
            return false
          }
          return true
        })
        if (config?.items) {
          item = config?.items
        }
      } else {
        if (configOptions?.items) {
          item = configOptions?.items
        }
      }
      if (item > 0 && item > slideImages?.length && !options?.loop) {
        return true
      }
    }
    return false
  }
  const renderTitle = () => {
    const _url = moreInfo?.[configKeySlide.url]
    const title = data?.[homeSlidesFields.fTitle]
    const description = data?.[homeSlidesFields.fDescription] || ""
    const config4SEO = config?.config4SEO || {
      useHeading: true,
      headingTag: "h2",
    }
    if (title) {
      return (
        <WrapTitle className="col-sm-12 title-home d-flex">
          {title && (
            // <h2
            //   className="maintxt"
            //   dangerouslySetInnerHTML={{ __html: title }}
            // ></h2>
            <MyUI
              type="sg_seo_title"
              text={title}
              className="maintxt"
              {...config4SEO}
            />
          )}
          {description && (
            <div
              className="description html_content"
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          )}
          {/* {_url && (
            <a className="pull-right hidden-mobile more-btn" href={_url}>
              Xem thêm
            </a>
          )} */}
        </WrapTitle>
      )
    }
    return <></>
  }
  if (config?.[configKeySlide.only_image]) {
    if (slideImages && slideImages?.length > 0) {
      if (slideImages?.length > 1) {
        return (
          <Wrap className={classNameWrap}>
            {slideImages && (
              <OwlCarousel
                options={options}
                events={events}
                className="owldd owl-carousel owl-theme wrap-item"
              >
                {slideImages.map((v, i) => {
                  return (
                    <div
                      className="item"
                      key={i}
                      onClick={() => {
                        let _url = data?.["Url"]
                        let _target = data?.["UrlTarget"] || "self"
                        if (_url) {
                          HLink.openUrl(_url, _target)
                        }
                      }}
                    >
                      {configOptions.lazyLoad ? (
                        <img data-src={v} className="owl-lazy" alt="" />
                      ) : (
                        <img src={v} alt="" />
                      )}
                    </div>
                  )
                })}
              </OwlCarousel>
            )}
          </Wrap>
        )
      }
      return (
        <div className="item empty-img">
          <img src={slideImages[0]} alt="" />
        </div>
      )
    }
    return (
      <div className="item empty-img">
        <img src={config?.[configKeySlide.default_image]} alt="" />
      </div>
    )
  } else if (slideImages?.length > 0 && config?.cardType) {
    const _url = moreInfo?.[configKeySlide.url]
    const _configBtnNavigation = slideSetting?.configBtnNavigation || {}
    const _configDots = slideSetting?.configDots || {}
    const classNameSlide = slideSetting?.classNameSlide || ""
    const isCenter = getIsCenter()
    return (
      <Wrap
        className={className}
        configBtnNavigation={_configBtnNavigation}
        configDots={_configDots}
      >
        {renderTitle()}
        <div
          className={classnames(
            "home-slide",
            classNameWrap,
            alwaysShowNav && "always-show-nav",
            classNameSlide && classNameSlide,
            isCenter && "always-center"
          )}
        >
          <OwlCarousel
            options={options}
            events={events}
            className={classnames(
              "owldd owl-carousel owl-theme",
              slideSetting?.classNameSlide && slideSetting?.classNameSlide
            )}
          >
            {slideImages.map((v, i) => {
              return (
                <div className="item" key={i}>
                  <MyCard
                    type={config?.cardType}
                    data={v}
                    fnList={fnList}
                    config={config}
                    configMoreInfo={moreInfo}
                  />
                </div>
              )
            })}
          </OwlCarousel>
        </div>
        {_url && (
          <div className="col-sm-12 hidden-desktop more-btn">
            <a style={{ paddingTop: "10px" }} href={_url}>
              Xem thêm
            </a>
          </div>
        )}
      </Wrap>
    )
  }
  return <></>
}
const WrapTitle = styled.div`
  &.title-home {
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
  }
  & .wrap-title {
    align-items: center;
    justify-content: space-between;
  }
  & .maintxt {
    font-size: 36px;
    font-weight: 700;
    color: var(--color-text-000);
    text-align: center;
    line-height: 49px;
    margin-bottom: 0;
    max-width: 80%;
  }
  & .description {
    color: var(--color-text-000);
    font-size: 16px;
    text-align: center;
    padding-top: 15px;
    max-width: 80%;
  }
  @media (max-width: 1199px) {
    & .maintxt {
      max-width: 90%;
    }
  }
  @media (max-width: 767px) {
    & .maintxt {
      font-size: 30px;
      line-height: 39px;
      max-width: 100%;
    }
    & .description {
      padding-top: 10px;
      max-width: 100%;
    }
  }
`
const Wrap: any = styled.div`
  padding: 0;
  .owl-carousel,
  .owl-carousel .owl-item {
    -webkit-tap-highlight-color: transparent;
    position: relative;
  }

  .owl-carousel {
    display: none;
    width: 100%;
    z-index: 1;
  }

  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;
  }

  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .owl-carousel .owl-item,
  .owl-carousel .owl-wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }

  .owl-carousel .owl-item {
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
  }

  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
  }

  .owl-carousel .owl-dots.disabled,
  .owl-carousel .owl-nav.disabled {
    display: none;
  }

  .no-js .owl-carousel,
  .owl-carousel.owl-loaded {
    display: block;
  }

  .owl-carousel .owl-dot,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-carousel.owl-loading {
    opacity: 0;
    display: block;
  }

  .owl-carousel.owl-hidden {
    opacity: 0;
  }

  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden;
  }

  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
  }

  .owl-carousel.owl-rtl {
    direction: rtl;
  }

  .owl-carousel.owl-rtl .owl-item {
    float: right;
  }

  .owl-carousel .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  .owl-carousel .owl-animated-in {
    z-index: 0;
  }

  .owl-carousel .owl-animated-out {
    z-index: 1;
  }

  .owl-carousel .fadeOut {
    animation-name: fadeOut;
  }
  & .owl-dots {
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    max-width: 80px;
    overflow: hidden;
    bottom: ${(props: any) =>
      props?.configDots && props?.configDots?.bottom
        ? props?.configDots?.bottom
        : "10px"};
    & .owl-dot {
      width: ${(props: any) =>
        props?.configDots && props?.configDots?.defaultWidth
          ? props?.configDots?.defaultWidth
          : "5px"};
      height: ${(props: any) =>
        props?.configDots && props?.configDots?.defaultWidth
          ? props?.configDots?.defaultWidth
          : "5px"};
      min-width: ${(props: any) =>
        props?.configDots && props?.configDots?.defaultWidth
          ? props?.configDots?.defaultWidth
          : "5px"};
      background-color: ${(props: any) =>
        props?.configDots && props?.configDots?.defaultColor
          ? props?.configDots?.defaultColor
          : "var(--color-text-e1e1e1)"};
      margin-right: ${(props: any) =>
        props?.configDots && props?.configDots?.marginRight
          ? props?.configDots?.marginRight
          : "5px"};
    }
    & .owl-dot.active {
      background-color: ${(props: any) =>
        props?.configDots && props?.configDots?.activeColor
          ? props?.configDots?.activeColor
          : "var(--color-text-fff)"};
      width: ${(props: any) =>
        props?.configDots && props?.configDots?.activeWidth
          ? props?.configDots?.activeWidth
          : "7px"};
      height: ${(props: any) =>
        props?.configDots && props?.configDots?.activeWidth
          ? props?.configDots?.activeWidth
          : "7px"};
      min-width: ${(props: any) =>
        props?.configDots && props?.configDots?.activeWidth
          ? props?.configDots?.activeWidth
          : "7px"};
    }
  }
  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  .owl-height {
    transition: height 0.5s ease-in-out;
  }

  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 0.4s ease;
  }

  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d;
  }

  .owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }

  .owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url(owl.video.play.png) no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 0.1s ease;
  }

  .owl-carousel .owl-video-play-icon:hover {
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }

  .owl-carousel .owl-video-playing .owl-video-play-icon,
  .owl-carousel .owl-video-playing .owl-video-tn {
    display: none;
  }

  .owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 0.4s ease;
  }

  .owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
  & .custom-nav-position-top {
    & .owl-nav {
      & .owl-prev,
      & .owl-next {
        top: ${(props: any) =>
          props?.configBtnNavigation && props?.configBtnNavigation?.default
            ? props?.configBtnNavigation?.default
            : "calc(50% - 15px)"};
      }
    }
  }
  & .custom-nav-position {
    & .owl-nav {
      & .owl-prev {
        left: ${(props: any) =>
          props?.configBtnNavigation && props?.configBtnNavigation?.leftDefault
            ? props?.configBtnNavigation?.leftDefault
            : "-15px"};
      }
      & .owl-next {
        right: ${(props: any) =>
          props?.configBtnNavigation && props?.configBtnNavigation?.rightDefault
            ? props?.configBtnNavigation?.rightDefault
            : "-15px"};
      }
    }
  }
  & .owl-prev,
  & .owl-next {
    i {
      color: #c5c5c5;
    }
  }
  & .item {
    transition: all 0.25s;
    // &::before {
    //   transition: all 0.25s;
    //   content: " ";
    //   position: absolute;
    //   background-color: var(--color-text-fff);
    //   top: 0;
    //   width: 100%;
    //   height: 100%;
    //   opacity: 0;
    // }
    & > div {
      width: 100%;
    }
  }
  & .always-show-nav {
    .owl-carousel .owl-nav.disabled {
      display: block;
      opacity: 1;
      pointer-events: unset;
      touch-action: unset;
    }
  }
  & .always-show-overflow {
    & .owl-carousel .owl-stage-outer {
      overflow: visible;
    }
  }
  & .always-show-dots {
    & .owl-carousel .owl-dots.disabled {
      display: block;
    }
  }
  & .wrap-item {
    .owl-nav {
      opacity: 0;
      pointer-events: none;
      touch-action: none;
      transition: all 0.25s;
      visibility: hidden;
    }
  }
  & .wrap-item:hover {
    .owl-nav {
      display: block;
      opacity: 1;
      pointer-events: unset;
      touch-action: unset;
      visibility: visible;
      & .disabled {
        display: block;
        opacity: 0;
        user-select: unset;
        pointer-events: unset;
        touch-action: unset;
      }
    }
    & .owl-item.active {
      & .item {
        position: relative;
      }
      // & .item::before {
      //   opacity: 0.2;
      // }
    }
  }
  & .always-center .owl-stage-outer {
    display: flex;
    justify-content: center;
  }
  @media (min-width: 1000px) {
    & .show-overflow-desktop {
      & .owl-carousel .owl-stage-outer {
        overflow: visible;
      }
    }
  }
  @media (max-width: 996px) {
    & .custom-nav-position-top {
      & .owl-nav {
        & .owl-prev,
        & .owl-next {
          top: ${(props: any) =>
            props?.configBtnNavigation && props?.configBtnNavigation?.mobile
              ? props?.configBtnNavigation?.mobile
              : "calc(50% - 15px)"};
        }
      }
    }
    & .custom-nav-position {
      & .owl-nav {
        & .owl-prev {
          left: ${(props: any) =>
            props?.configBtnNavigation &&
            props?.configBtnNavigation?.leftDefaultMobile
              ? props?.configBtnNavigation?.leftDefaultMobile
              : "-15px"}!important;
        }
        & .owl-next {
          right: ${(props: any) =>
            props?.configBtnNavigation &&
            props?.configBtnNavigation?.rightDefaultMobile
              ? props?.configBtnNavigation?.rightDefaultMobile
              : "-15px"}!important;
        }
      }
    }
    & .wrap-item {
      .owl-nav {
        opacity: 1;
        display: block;
        pointer-events: unset;
        touch-action: unset;
        visibility: visible;
      }
    }
  }
  @media (max-width: 767px) {
    & .owl-nav {
      & .owl-prev {
        left: -12px;
      }
      & .owl-next {
        right: -12px;
      }
    }
    & .custom-nav-position-top {
      & .owl-nav {
        & .owl-prev,
        & .owl-next {
          top: ${(props: any) =>
            props?.configBtnNavigation && props?.configBtnNavigation?.mobile
              ? props?.configBtnNavigation?.mobile
              : "calc(50% - 15px)"};
        }
      }
    }
    & .item > div.mobile-padding-32 {
      padding: 0 32px !important;
    }
  }
`
export default MyUISlideCard
